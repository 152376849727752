import {
  Chart as ChartJS,
} from "chart.js";
import { DeltaStats, TrendsApi } from "../services/TrendsApi"
import { FormatTrafficRate } from "../components/TrendsTable"
import { useEffect, useState } from "react"
import { Box, Grid } from "@mui/material"
import { Dropdown } from "./Dropdown"
import { RegionSelector } from "./RegionSelector"
import { TreemapController, TreemapElement } from 'chartjs-chart-treemap';
import { ALL, useParamsContext } from "../context/ParamsContext"
import { useNavigate } from "react-router"
import { Chart } from 'react-chartjs-2';
import { color } from 'chart.js/helpers';
import { TimeInterval } from "./SelectTime"
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
  TreemapElement,
  TreemapController,
  zoomPlugin,
);

export type Scope = "weekly" | "monthly" | "weekly_peak" | "monthly_peak"

const scopeOptions: {value: Scope, label: string}[] = [
  { value: "weekly", label: "Last week average" },
  { value: "monthly", label: "Last month average" },
  { value: "weekly_peak", label: "Last week peak" },
  { value: "monthly_peak", label: "Last month peak" },
]

type BandwidthData = {
  name: string
  organization_id: string
  bandwidth: number
  gradient?: number
};

function parseScopeData(scopeData: DeltaStats[]) {
  var total:number = 0;
  var bandwidths: BandwidthData[] = [];
  for (var org of scopeData) {
    let item: BandwidthData = {
      name: org.organisation.name,
      organization_id: org.organisation.id,
      bandwidth: org.current_value,
    };
    bandwidths.push(item);
    total += org.current_value;
  }
  var sortedBandwidths: BandwidthData[] = bandwidths.sort((n1,n2) => {
    if (n1.bandwidth > n2.bandwidth) {
      return -1;
    }
    if (n1.bandwidth < n2.bandwidth) {
      return 1;
    }
    return 0;
  });
  var currentGradient:number = 0.6;
  var gradientDelta = 0.007;
  var minGradient = 0.1;
  for (var item of sortedBandwidths) {
    item.gradient = Math.max(currentGradient, minGradient)
    currentGradient -= gradientDelta;
  }
  return sortedBandwidths;
}

export const BandwidthTreemap = () => {
  const [config, setConfig] = useState<any>({})
  const { setContextOrganisationId } = useParamsContext()
  const navigate = useNavigate()
  const [selectedScope, setSelectedScope] = useState<Scope>("weekly")
  const [selectedRegion, setSelectedRegion] = useState<string>()

  const handleClick = (event: any, arr: any) => {
    if (arr.length > 0) {
      navigateToChart(config.data.datasets[0].tree[arr[0].index].organization_id);
    }
  }

  const navigateToChart = (organisationId: string) => {
    const hashParams = new URLSearchParams();
    const timeRange = selectedScope.split("_")[0] as TimeInterval
    const region = (selectedRegion || ALL).toLowerCase()
    hashParams.append("region", region)
    hashParams.append("time", timeRange)
    hashParams.append("organisation", organisationId);
    setContextOrganisationId(organisationId)
    navigate(`/private#${hashParams.toString()}`);
  }
  
  let originalZoom = 'original' as const;
  const options = {
    onClick: handleClick,
    plugins: {
      legend: {
        display: false,
      },
      zoom: {
        limits: {
          y: {min: 0, max: originalZoom},
          x: {min: 0, max: originalZoom},
        },
        zoom: {
          wheel: {
            enabled: true,
          },
        },
        pan: {
          enabled: true,
        }
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  useEffect(() => {
    window.history.replaceState({}, document.title, window.origin + "/customergraph")
    void (async function() {
      const trendsApi = new TrendsApi(process.env.REACT_APP_IX_STATS_BACKEND_URL)
      let newScopeData: DeltaStats[] = []
      switch (selectedScope) {
      case "weekly":
        newScopeData = await trendsApi.getWeeklyTrend(selectedRegion)
        break
      case "monthly":
        newScopeData = await trendsApi.getMonthlyTrend(selectedRegion)
        break
      case "weekly_peak":
        newScopeData = await trendsApi.getWeeklyPeakTrend(selectedRegion)
        break
      case "monthly_peak":
        newScopeData = await trendsApi.getMonthlyPeakTrend(selectedRegion)
        break
      }
      let overflowFit = 'fit' as const;
      setConfig({
        type: 'treemap',
        data: {
          datasets: [ 
            {
              tree: parseScopeData(newScopeData),
              data: [],
              key: 'bandwidth',
              labels: {
                display: true,
                font: [{weight: 'bold'}, {weight: 'normal'}],
                formatter: (context: any) => [context.raw._data.name, FormatTrafficRate(context.raw._data.bandwidth)],
                overflow: overflowFit,
              },
              backgroundColor(context: any) {
                if (context.type !== 'data') return 'transparent';
                const { gradient } = context.raw._data;
                return gradient === 0 ? color('grey').rgbString() : color('blue').alpha(gradient).rgbString();
              },
            }
          ]
        },
      })
    })()
  }, [setConfig, selectedRegion, selectedScope])

  let description = ""
  switch (selectedScope) {
  case "weekly":
    description = "The weekly average scope shows the average rate for the sum of traffic in both directions."
    break
  case "monthly":
    description = "The monthly average scope shows the average rate for the sum of traffic in both directions."
    break
  case "weekly_peak":
    description = "The weekly peak scope shows the highest peak in either direction of traffic."
    break
  case "monthly_peak":
    description = "The monthly peak scope shows the highest peak in either direction of traffic."
    break
  }
  let topHeight = (window.document.getElementById("dropdown")?.clientHeight || 0) + (window.document.getElementById("description")?.clientHeight || 0);
  return (
    <Box style={{height: `calc(100vh - 20px - ${topHeight}px)`}}>
      <Grid id="dropdown" container spacing={2} sx={{ marginBottom: "10px" }}>
        <Grid item xs={12} sm={8}>
          <Dropdown
            options={scopeOptions}
            value={selectedScope}
            onChange={({ target: { value } }) => setSelectedScope(value as Scope)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <RegionSelector
            initialSelectedRegion={selectedRegion}
            onChange={(newRegion) => setSelectedRegion(newRegion)} />
        </Grid>
      </Grid>
      <Box id="description" sx={{ marginTop: "10px", marginBottom: "10px" }}>{description}</Box>
      {config?.data&&<Chart type="treemap" data={config.data} options={options} />}
    </Box>
  )
}
