import { DeltaStats } from "../services/TrendsApi"
import { DataGrid, GridColDef, GridRowParams, GridValueGetterParams } from "@mui/x-data-grid";
import { Trend } from "./Trends"
import { Box } from "@mui/material"

type TrendsTableProps = {
  deltaStats: DeltaStats[]
  loading: boolean
  trend: Trend
  onRowClick: (organisationId: string) => void
}

export const TrendsTable = ({ deltaStats, loading, trend, onRowClick }: TrendsTableProps) => {
  let currentPeriod, previousPeriod: Period | undefined
  if (deltaStats.length > 0) {
    const firstDeltaStats = deltaStats[0]
    currentPeriod = { start: firstDeltaStats.current_start, end: firstDeltaStats.current_end }
    previousPeriod = { start: firstDeltaStats.previous_start, end: firstDeltaStats.previous_end }
  }

  return <DataGrid
    rows={deltaStats.map((item) => ({ id: item.organisation.id, ...item }))}
    columns={columns(trend, previousPeriod, currentPeriod)}
    autoHeight={true}
    loading={loading}
    sortingOrder={["desc", "asc"]}
    pageSizeOptions={[10, 25, 50, 100]}
    onRowClick={(params: GridRowParams<DeltaStats>) => onRowClick(params.row.organisation.id)}
    initialState={{
      pagination: {
        paginationModel: {
          page: 0,
          pageSize: 25,
        },
      },
      sorting: {
        sortModel: [{ field: "current_value", sort: "desc" }]
      }
    }}
  />
}

const columns: (trend: Trend, previousPeriod?: Period, currentPeriod?: Period) => GridColDef[] = (
  trend,
  previousPeriod,
  currentPeriod) => [
  {
    field: "organisation",
    headerName: "Organisation",
    valueGetter: (params: GridValueGetterParams<DeltaStats>) =>
      params.row.organisation.name,
    flex: 2,
  },
  {
    field: "current_value",
    headerName: `Current ${trendPeriodName(trend)}`,
    type: "number",
    flex: 1,
    renderHeader: (params) => (<HeaderName headerName={params.colDef.headerName} period={currentPeriod} />),
    valueFormatter: ({ value }: {value: number}) => FormatTrafficRate(value)
  },
  {
    field: "previous_value",
    headerName: `Previous ${trendPeriodName(trend)}`,
    type: "number",
    flex: 1,
    renderHeader: (params) => (<HeaderName headerName={params.colDef.headerName} period={previousPeriod} />),
    valueFormatter: ({ value }: {value: number}) => FormatTrafficRate(value)
  },
  {
    field: "value_delta",
    headerName: "Difference",
    type: "number",
    flex: 1,
    valueFormatter: ({ value }: {value: number}) => FormatTrafficRate(value)
  },
  {
    field: "percent_delta",
    headerName: "Difference %",
    type: "number",
    flex: 1,
    valueFormatter: ({ value }: {value: number}) =>`${value.toFixed(1)}%`
  },
]

export const FormatTrafficRate = (trafficRateInBytes: number) => {
  const trafficRateInBits = trafficRateInBytes * 8

  const terabit = Math.pow(10, 12)
  const gigabit = Math.pow(10, 9)
  const megabit = Math.pow(10, 6)
  const kilobit = Math.pow(10, 3)

  if (Math.abs(trafficRateInBits) >= terabit) return `${(trafficRateInBits/terabit).toFixed(1)} Tbit/s`
  if (Math.abs(trafficRateInBits) >= gigabit) return `${(trafficRateInBits/gigabit).toFixed(1)} Gbit/s`
  if (Math.abs(trafficRateInBits) >= megabit) return `${(trafficRateInBits/megabit).toFixed(1)} Mbit/s`
  if (Math.abs(trafficRateInBits) >= kilobit) return `${(trafficRateInBits/kilobit).toFixed(1)} Kbit/s`


  return `${(trafficRateInBits).toFixed(1)} bit/s`
}

type Period = {start: number, end: number}
type PeriodName = "week" | "month"
const trendPeriodName = (trend: Trend): PeriodName => {
  switch (trend) {
  case "weekly": return "week"
  case "weekly_peak": return "week"
  case "monthly": return "month"
  case "monthly_peak": return "month"
  }
}

const HeaderName = ({ headerName, period }: HeaderNameParams) => {
  return (
    <Box sx={{
      overflow: "hidden",
    }}>
      <Box sx={{ ...columnHeaderTextStyle,fontWeight: 500 }}>{headerName}</Box>
      {period &&
          <Box sx={{ ...columnHeaderTextStyle, color: "gray" }}>
            {formatDate(period.start)} - {formatDate(period.end)}
          </Box>}
    </Box>)
}

type HeaderNameParams = {
  headerName?: string,
  period?: Period
}

const columnHeaderTextStyle = {
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  lineHeight: "26px",
  textAlign: "end",
}

const formatDate = (timestamp: number) => {
  const date = new Date(timestamp*1000) // seconds to milliseconds

  return `${date.getDate()}/${date.getMonth()+1}`
}
