
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { Box } from "@mui/material"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { Trends } from "./components/Trends"
import { BandwidthTreemap } from "./components/BandwidthTreemap"
import { ScriptHostProvider } from "./context/ScriptHostContext"
import { ParamsProvider } from "./context/ParamsContext"
import { StatisticsPanel } from "./components/StatisticsPanel"

const queryClient = new QueryClient({
  defaultOptions: {},
})

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Box sx={{ display: "flex", flex: 1, flexDirection: "column", height: "95vh", position: "relative" }}>
        <StatisticsPanel />
      </Box>
    ),
    errorElement:
      <Box sx={{ display: "flex", flex: 1, flexDirection: "column", height: "500px", position: "relative" }}>
        <StatisticsPanel />
      </Box>
  },
  {
    path: "/private",
    element: (
      <Box sx={{ display: "flex", flex: 1, flexDirection: "column", height: "95vh", position: "relative" }}>
        <StatisticsPanel />
      </Box>
    ),
  },
  {
    path: "trends",
    element: <Trends />,
  },
  {
    path: "customergraph",
    element: <BandwidthTreemap />,
  },
  {
    path: "ix/clone-of-ix-statistics",
    element: (
      <Box sx={{ display: "flex", flex: 1, flexDirection: "column", height: "500px", position: "relative" }}>
        <StatisticsPanel />
      </Box>
    ),
  },
  {
    path: "ix/statistics",
    element: (
      <Box sx={{ display: "flex", flex: 1, flexDirection: "column", height: "500px", position: "relative" }}>
        <StatisticsPanel />
      </Box>
    ),
  }
])

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ScriptHostProvider>
        <ParamsProvider>
          <RouterProvider router={router} />
        </ParamsProvider>
      </ScriptHostProvider>
    </QueryClientProvider>
  )
}

export default App
